import React, { useState } from "react";
import "./style.css";
import { cn } from "../../utils";

function AnswerInputField({ label, placehoder, required, value, onChange }) {
  return (
    <div>
      <div>
        <div className={cn('font-heading2' , 'banner')}>
          <p>{label} {required && "*"}</p>
        </div>
        <div className="input-container">
          <input
            type="text"
            className="custom-input"
            placeholder={placehoder}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}

export default AnswerInputField;
