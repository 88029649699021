import React, { useState, useContext } from "react";
import AnswerSelector from "../components/AnswerSelector";
import HeaderQuestion from "../components/HeaderQuestion";
import SkipButton from "../components/SkipButton";
import Button from "../components/Button";
import { HashLink } from "react-router-hash-link";
import { UserContext } from "../UserContext";
import ErrorCard from "../components/ErrorCard";
import HeroWrapper from "../components/backgground/background";

function FivethPage() {
  const [selectedItem, setSelectedItem] = useState(null);
  const { desiredSoftwareEngineerCount, setDesiredSoftwareEngineerCount } =
    useContext(UserContext);

    const { desiredSoftwareEngineerCountError, setDesiredSoftwareEngagementCountError } =
    useContext(UserContext);

  const isButtonDisabled = selectedItem === null;


  const handleAnswerSelectorClick = (title, index) => {
    setDesiredSoftwareEngineerCount(title);
    setSelectedItem(index);
    setDesiredSoftwareEngagementCountError("");
  };

  const handleOKButtonClick = () => {
    if (!desiredSoftwareEngineerCount) {
      handleError();
    } else {
      setDesiredSoftwareEngagementCountError("");
    }
  };

  const handleError = () => {
    setDesiredSoftwareEngagementCountError("Oops! Please make a selection");
  };

  const choices = [
    {
      id: "A",
      title: "1 - 5",
    },
    {
      id: "B",
      title: "6 - 10s",
    },
    {
      id: "C",
      title: "10+",
    },
  ];
  return (
    <div id="fiveth" className="pageContainer">
      <div>
        <HeaderQuestion
          numbeQuestion="5"
          title="How many software engineers are you looking to hire?"
          required={true}
          description="You can start with 1 or 1 0. We have the talent ready!"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 10,
          }}
        >
          {choices.map((choice, index) => (
            <div style={{ width: 200 }} key={index}>
              <HashLink
                style={{ color: "white", textDecoration: "none" }}
                smooth
                to={`/#sixth`}
              >
                <AnswerSelector
                  id={choice.id}
                  title={choice.title}
                  isSelected={index === selectedItem} // Compare index with selectedItem
                  onClick={() => handleAnswerSelectorClick(choice.title, index)}
                />
              </HashLink>
            </div>
          ))}
        </div>
        <HeroWrapper   />
        <div style={{ marginTop: "10px" }}>
         {
          desiredSoftwareEngineerCountError ?
          <ErrorCard
              sub="Oops!"
              title="Please make a selection"
              width={210}
            />
            :
          <Button
            title="OK"
            navigateTo="sixth"
            onClick={handleOKButtonClick}
            disabled={isButtonDisabled}
          />
         }
        </div>
      </div>

      <SkipButton
        nextButton="sixth"
        previousButton="fourth"
        isButtonDisabled={isButtonDisabled}
        onNextClicked={handleOKButtonClick}
      />
    </div>
  );
}

export default FivethPage;
