import { motion } from "framer-motion";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../utils/constant";
import HeroWrapper from "../components/backgground/background";
import { HashLink } from "react-router-hash-link";

const ZerothPage = (props) => {
  return (
    <div
      className="w-full min-h-screen flex flex-col justify-center items-center"
      id="zeroth"
    >
      <motion.div
        className="max-w-7xl px-5 xl:px-0 font-display2"
        initial="hidden"
        whileInView="show"
        animate="show"
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
      >
        <motion.h1
          className="text-gradient mb-4 translate-y-[30%] text-center text-4xl bg-gradient-to-tr from-zinc-400/20 via-white/90 text-transparent bg-clip-text to-white/10 sm:text-6xl  md:text-7xl lg:text-8xl [transition:transform_1000ms_cubic-bezier(0.3,1.17,0.55,0.99)0s] [.is-visible_&]:translate-y-"
          variants={FADE_DOWN_ANIMATION_VARIANTS}
        >
          Let's Get Started!
        </motion.h1>
        <motion.p
          className="mt-2 font-heading2 text-muted-foreground text-center text-gray-300 md:text-xl font-display2 text-4xl bg-gradient-to-tr from-zinc-600/90 via-zinc-500/80 to-black/90  bg-clip-text "
          variants={FADE_DOWN_ANIMATION_VARIANTS}
        >
          <div className="flex justify-center items-center gap-2">
            <motion.div
              variants={FADE_DOWN_ANIMATION_VARIANTS}
              className="-mb-4"
            >
              <HashLink to={"/#first"} smooth className="hover:cursor-pointer select-none" >
                <div className="group mx-auto mt-6 text-lg flex max-w-fit items-center justify-center space-x-2 rounded-2xl border border-black bg-black px-8 py-3  text-white transition-colors hover:bg-white hover:text-black">
                  Start
                </div>
              </HashLink>
            </motion.div>
          </div>
        </motion.p>
      </motion.div>
    </div>
  );
};

export default ZerothPage;
