import React from "react";
import "./style.css";
import { cn } from "../../utils";

function AnswerSelector({ id, title, isSelected, onClick }) {
  return (
    <div
      className={cn(isSelected ? "selectionContainer" : "selectionContainer" , 'bg-gradient-from-tr from-purple-500 via-purple-300 to-orange-300' )}
      onClick={() => onClick(title)}
    >
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <p className={isSelected ? "choiced" : "choice"}>
          <span>{id}</span>
        </p>
        <p className="choiceTitle">{title} </p>
      </div>
      <p className="choiceMarker">{isSelected && "✓"}</p>
    </div>
  );
}

export default AnswerSelector;
