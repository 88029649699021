import React from "react";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

function SkipButton({
  nextButton,
  previousButton,
  isNextButtonInActive,
  isPrevButtonInActive,
  isButtonDisabled,
  onNextClicked,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        marginBottom: "10px",
      }}
    >
      <div
      className="bg-gradient-to-tr from-purple-500/10 via-transparent to-transparent border-[0.3px] border-white/10"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "red",
          width: "100px",
          height: "100%",
          borderRadius: "30px",
        }}
      >
     <HashLink
          className="nav-link navbar-section-link"
          style={{
            cursor: isPrevButtonInActive ? "auto" : "",
          }}
          smooth
          to={`/#${previousButton}`}
        >
          <FontAwesomeIcon
            icon={faChevronUp}
            color={isPrevButtonInActive ? "grey" : "white"}
          />
        </HashLink>
        <div
          style={{
            width: "1px",
            backgroundColor: "#403939",
            height: "30px",
            margin: "0 10px",
          }}
        ></div>
        <HashLink
          className="nav-link navbar-section-link"
          smooth
          to={!isButtonDisabled && `/#${nextButton}`}
          style={{
            cursor: isNextButtonInActive ? "auto" : "",
          }}
          onClick={onNextClicked}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            color={isNextButtonInActive ? "grey" : "white"}
          />
        </HashLink>
      </div>
    </div>
  );
}

export default SkipButton;
