import React, { useContext } from "react";
import HeaderQuestion from "../components/HeaderQuestion";
import AnswerInputField from "../components/AnswerInputField";
import SkipButton from "../components/SkipButton";
import Button from "../components/Button";
import { UserContext } from "../UserContext";
import ErrorCard from "../components/ErrorCard";

function FirstPage() {
  const { firstName, setFirstName } = useContext(UserContext);
  const { lastName, setLastName } = useContext(UserContext);

  const { firstNameErrr, setFirstNameErr } = useContext(UserContext);
  const { lastNameErr, setLastNameErr } = useContext(UserContext);

  const { developerTimeCommitment } = useContext(UserContext);

  const isButtonDisabled = firstName === "" || lastName === "";

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameErr("");
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameErr("");
  };

  const handleOKButtonClick = () => {
    if (!firstName || !lastName) {
      handleError();
    } else {
      setFirstNameErr("");
      setLastNameErr("");
    }
  };

  const handleError = () => {
    if (!firstName && !lastName) {
      setFirstNameErr("Please fill in first name");
      setLastNameErr("Please fill in last name");
    } else if (!firstName) {
      setFirstNameErr("Please fill in first name");
    } else {
      setLastNameErr("Please fill in last name");
    }
  };

  return (
    <div id="first" className="pageContainer">
      <div>
        <HeaderQuestion
          numbeQuestion="1"
          title="What’s your first and last name?"
          required={true}
          description="Nice to meet you!"
        />

        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <AnswerInputField
            label="First Name"
            placehoder="Jane"
            required={true}
            value={firstName}
            onChange={handleFirstNameChange}
          />
          {firstNameErrr && (
            <ErrorCard title="Please fill this in" width={150} />
          )}
          <AnswerInputField
            label="Last Name"
            placehoder="Smith"
            required={true}
            value={lastName}
            onChange={handleLastNameChange}
          />
          {firstNameErrr && lastNameErr ? (
            <ErrorCard title="Please fill this in" width={150} />
          ) : firstNameErrr ? (
            ""
          ) : lastNameErr ? (
            <ErrorCard title="Please fill this in" width={150} />
          ) : (
            <Button
              title="OK"
              navigateTo="second"
              onClick={handleOKButtonClick}
              disabled={isButtonDisabled}
            />
          )}
        </div>
      </div>
      <SkipButton
        nextButton="second"
        previousButton={
          developerTimeCommitment ===
          "Help with creation of an app/website/software (we’d manage)"
            ? "zeroth"
            : "zeroth"
        }
        isPrevButtonInActive={true}
        isButtonDisabled={isButtonDisabled}
        onNextClicked={handleOKButtonClick}
      />
    </div>
  );
}

export default FirstPage;
