

import React, { useState, useEffect } from "react";
import HeaderQuestion from "../components/HeaderQuestion";
import { InlineWidget, PopupButton, useCalendlyEventListener } from "react-calendly";
import SkipButton from "../components/SkipButton";
import Button from "../components/Button";

const EighthPage = React.forwardRef((props, ref) =>{
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false)

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => console.log(e.data.payload),
  });

  return (
    <div
      ref={ref}
      id="sixth"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "92%",
      }}
    >
      <div className="calendly">
        <div style={{ marginLeft: "1px" }}>
          <HeaderQuestion numbeQuestion="6" title="Schedule a meeting" required={false} />
         {
          windowWidth > 610 &&  <div>
          <Button title="OK" navigateTo="seventh" />
        </div>
         }
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            overflow: "hidden",
            alignItems: "center"
          }}
          className="mobile"
          id="modal"
        >
          {windowWidth < 550 ? (
           <>
            <PopupButton
              url="https://cal.com/armada/15min"
              onModalClose={() =>(setIsOpen(false))}
              open={isOpen}
              rootElement={document.getElementById("modal")}
              text="Click here to schedule!"
              pageSettings={{
                backgroundColor: '#ffffff',
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: '#00a2ff',
              }}
              styles={{
                height: '50px',
                width:"80%",
                borderRadius: "20px",
                backgroundColor: "#332A4C",
                border: "2px #ffff solid",
                color: "#ffff",
                fontWeight: "100",
                cursor: "pointer"
              }}
            />
           </>
          ) : (
            <InlineWidget
              url="https://cal.com/armada/15min"

              styles={{
                height: "80vh",
                width: "100%",
                padding: 0,
                marginLeft: '0',
                fontSize:'10px',
                fontWeight:"100"
              }}
              pageSettings={{
                backgroundColor: "ffffff",
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: "00a2ff",
                textColor: "4d5055",
              }}
            />
          )}


        </div>
        {
          windowWidth < 610 &&
          <div style={{width: "60%"}}>
            <Button title="OK" navigateTo="seventh" />
          </div>
         }
      </div>
      <SkipButton nextButton="seventh" previousButton="fiveth" />
    </div>
  );
})

export default EighthPage;
