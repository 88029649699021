import React from "react";
import "./style.css";
import { HashLink } from "react-router-hash-link";
import { cn } from "../../utils";

function Button({ title, navigateTo, disabled, onClick }) {
  return (
    <HashLink
      
      style={{ color: "#0000" }}
      className={cn('ok-button' , 'px-10 gap-2 py-2 border-[0.1px] rounded-full bg-gradient-to-tr from-purple-400/90 to-red-400/90 border-white/5')}
      smooth
      to={!disabled && `/#${navigateTo}`}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{title}</span>
      <span>✓</span>
    </HashLink>
  );
}

export default Button;
