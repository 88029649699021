import React, { useContext } from 'react';
import HeaderQuestion from "../components/HeaderQuestion";
import AnswerInputField from "../components/AnswerInputField";
import SkipButton from "../components/SkipButton";
import Button from "../components/Button";
import { UserContext } from '../UserContext';
import ErrorCard from '../components/ErrorCard';


function SecondPage() {

  const { email, setEmail } = useContext(UserContext);

  const emailRegex = /^[A-Z0-9._%+-]+@(?!icloud\.com)[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const { emailErr, setEmailErr } = useContext(UserContext);

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    setEmailErr("");
  };

  const handleOKButtonClick = () => {
    if (!email) {
      handleError();
    } else {
      if(emailRegex.test(email)){
        setEmailErr("");
      } else {
        setEmailErr("Hmm... that email doesn't look right");
      }

    }
  };

  const handleError = () => {
    if (!email) {
      setEmailErr("Please fill in");
    } else {
      setEmailErr("");
    }
  };

  const isButtonDisabled = email === "" || !emailRegex.test(email) ;
  return (
    <div
      id="second"
      className="pageContainer">
      <div>
        <HeaderQuestion
          numbeQuestion="2"
          title="What’s your email?"
          required={true}
        />

        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <AnswerInputField
            label="Email"
            placehoder="email@example.com"
            required={true}
            value={email}
            onChange={handleEmailChange}
          />
          {
            emailErr ?
            <ErrorCard title={emailErr} width={emailErr === "Hmm... that email doesn't look right"? 250:150}/> 
              :
            <Button
              title="OK"
              navigateTo="third"
              disabled={isButtonDisabled}
              onClick={handleOKButtonClick}
              />
          }
        </div>
      </div>
      <SkipButton
        nextButton="third"
        previousButton="first"
        isButtonDisabled={isButtonDisabled}
        onNextClicked={handleOKButtonClick}
      />
    </div>
  );
}

export default SecondPage
