import React, { useState, useContext } from "react";
import AnswerSelector from "../components/AnswerSelector";
import HeaderQuestion from "../components/HeaderQuestion";
import SkipButton from "../components/SkipButton";
import Button from "../components/Button";
import { HashLink } from "react-router-hash-link";
import { UserContext } from "../UserContext";
import ErrorCard from "../components/ErrorCard";

function ThirdPage() {
  const [selectedItem, setSelectedItem] = useState(null);
  const { developerTimeCommitment, setDeveloperTimeCommitment } =
    useContext(UserContext);
  const { developerTimeCommitmentError, setDeveloperTimeCommitmentError } =
    useContext(UserContext);

  const handleAnswerSelectorClick = (title, index) => {
    setDeveloperTimeCommitment(title);
    setSelectedItem(index);
    setDeveloperTimeCommitmentError("");
  };

  const isButtonDisabled = selectedItem === null;

  const handleOKButtonClick = () => {
    if (!developerTimeCommitment) {
      handleError();
    } else {
      setDeveloperTimeCommitmentError("");
    }
  };

  const choices = [
    {
      id: "A",
      title: "Software Developer 20/40 hrs per week (you’d manage)",
    },
    {
      id: "B",
      title: "UI/UX designer 20/40 hrs per week (you’d manage)",
    },
    {
      id: "C",
      title: "Help with creation of an app/website/software (we’d manage)",
    },
  ];

  const handleError = () => {
    setDeveloperTimeCommitmentError("Oops! Please make a selection");
  };

  return (
    <div id="third" className="pageContainer">
      <div>
        <HeaderQuestion
          numbeQuestion="3"
          title="What do you need help with? We offer full time (40 hours/week) and part time (20 hours/week)?"
          required={true}
        />

        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {choices.map((choice, index) => (
            <div style={{ width: "80% !important" }} key={index}>
              <HashLink
                style={{ color: "white", textDecoration: "none" }}
                smooth
                to={
                  choice.title ===
                  "Help with creation of an app/website/software (we’d manage)"
                    ? `/#fourth`
                    : `/#fourth`
                }
              >
                <AnswerSelector
                  id={choice.id}
                  title={choice.title}
                  isSelected={index === selectedItem}
                  onClick={() => handleAnswerSelectorClick(choice.title, index)}
                />
              </HashLink>
            </div>
          ))}
          {developerTimeCommitmentError ? (
            <ErrorCard
              sub="Oops!"
              title="Please make a selection"
              width={210}
            />
          ) : (
            <Button
              title="OK"
              navigateTo={
                developerTimeCommitment ===
                "Help with creation of an app/website/software (we’d manage)"
                  ? "fourth"
                  : "fourth"
              }
              onClick={handleOKButtonClick}
              disabled={isButtonDisabled}
            />
          )}
        </div>
      </div>

      <SkipButton
        nextButton={
          developerTimeCommitment ===
          "Help with creation of an app/website/software (we’d manage)"
            ? "fourth"
            : "fourth"
        }
        previousButton="second"
        isPrevButtonInActive={false}
        isButtonDisabled={isButtonDisabled}
        onNextClicked={handleOKButtonClick}
      />
    </div>
  );
}

export default ThirdPage;
