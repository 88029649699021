import React, { createContext, useState } from "react";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [formdata, setFormData] = useState(null);

  //

  const [developerTimeCommitment, setDeveloperTimeCommitment] = useState("");
  const [projectType, setProjectType] = useState("");
  const [staffCount, setStaffCount] = useState("");
  const [requiredSkills, setRequiredSkills] = useState([]);
  const [desiredSoftwareEngineerCount, setDesiredSoftwareEngineerCount] =
    useState("");
  const [foundUsSource, setFoundUsSource] = useState("");
  const [calendly, setCalendly] = useState("");

  //
  const [developerTimeCommitmentError, setDeveloperTimeCommitmentError] =
    useState("");
  const [
    desiredSoftwareEngineerCountError,
    setDesiredSoftwareEngagementCountError,
  ] = useState("");
  const [firstNameErrr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");

  return (
    <UserContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        loading,
        setLoading,
        formdata,
        setFormData,
        developerTimeCommitment,
        setDeveloperTimeCommitment,
        projectType,
        setProjectType,
        staffCount,
        setStaffCount,
        requiredSkills,
        setRequiredSkills,
        desiredSoftwareEngineerCount,
        setDesiredSoftwareEngineerCount,
        foundUsSource,
        setFoundUsSource,
        calendly,
        setCalendly,
        developerTimeCommitmentError,
        setDeveloperTimeCommitmentError,
        desiredSoftwareEngineerCountError,
        setDesiredSoftwareEngagementCountError,
        firstNameErrr,
        setFirstNameErr,
        lastNameErr,
        setLastNameErr,
        emailErr,
        setEmailErr,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
