import React, { useEffect, useContext } from "react";

import ZerothPage from "./pages/ZerothPage";
import FirstPage from "./pages/FirstPage";
import SecondPage from "./pages/SecondPage";
import ThirdPage from "./pages/ThirdPage";
import FourthPage from "./pages/FourthPage";
import FivethPage from "./pages/FivethPage";
import EighthPage from "./pages/EighthPage";
import NinethPage from "./pages/NinethPage";
import TenthPage from "./pages/TenthPage";
import { BrowserRouter as Router } from "react-router-dom";
import { UserContext } from "./UserContext";
import { cn } from "./utils";
import HeroWrapper from "./components/backgground/background";
import { applicationsBackendHandler } from "./applicationsService";

import "./App.css";

function App() {
  // a lot to do here
  const { firstName, lastName } = useContext(UserContext);
  const { email } = useContext(UserContext);
  const { developerTimeCommitment } = useContext(UserContext);
  const { projectType } = useContext(UserContext);
  const { staffCount } = useContext(UserContext);
  const { requiredSkills } = useContext(UserContext);
  const { desiredSoftwareEngineerCount } = useContext(UserContext);
  const { foundUsSource } = useContext(UserContext);
  const { calendly } = useContext(UserContext);

  const { loading, setLoading } = useContext(UserContext);
  const { formdata, setFormData } = useContext(UserContext);

  const handleSubmit = async () => {
    const formData = {
      firstName: firstName,
      lastName: lastName,
      companyEmail: email,
      developerTimeCommitment: developerTimeCommitment,
      projectType: projectType,
      staffCount: staffCount,
      requiredSkills: requiredSkills,
      desiredSoftwareEngineerCount: desiredSoftwareEngineerCount,
      foundUsSource: foundUsSource,
      calendly: calendly,
    };

    applicationsBackendHandler(formData, setLoading, setFormData);
  };
  const formDatas = {
    firstName: firstName,
    lastName: lastName,
    companyEmail: email,
    developerTimeCommitment: developerTimeCommitment,
    projectType: projectType,
    staffCount: staffCount,
    requiredSkills: requiredSkills,
    desiredSoftwareEngineerCount: desiredSoftwareEngineerCount,
    foundUsSource: foundUsSource,
    calendly: calendly,
  };

  useEffect(() => {
    const preventDefault = (e) => {
      e = e || window.event;
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    };

    const wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    window.addEventListener(wheelEvent, preventDefault, { passive: false });

    return () => {
      window.removeEventListener(wheelEvent, preventDefault);
    };
  }, []);

  // useEffect(() => {
  //   document.documentElement.style.overflow = "clip";
  // }, []);

  return (
    <Router>
      <div className="overflow-hidden max-h-[100dvh]">
      <div
        className={cn(
          "Container",
          "relative font-heading2 bg-gradient-to-br from-purple-400/5 via-transparent to-transparent backdrop-blur-2xl transition-all h-max px-6",
        )}
      >
        
        <ZerothPage id="zeroth" />
        <FirstPage id="first" />
        <SecondPage id="second" />
        <div className="">
          <HeroWrapper />
        </div>
        <ThirdPage id="third" />
        <FourthPage id="fourth" />
        <FivethPage id="fiveth" />
        {/* <Sixthpage id="sixth" />  */}
        {/* <SeventhPage id="seventh" /> */}
        <EighthPage id="sixth" />
        <NinethPage id="nineth" />
        {developerTimeCommitment ===
        "Help with development of an app/website/software (we’d manage)" ? (
          <></>
        ) : (
          <>
            <div className="screenContainer"></div>
          </>
        )}
        <TenthPage
          handleSubmit={handleSubmit}
          formDatas={formDatas}
          setFormData={setFormData}
          id="tenth"
        />
      </div>
      </div>
      
    </Router>
  );
}

export default App;
