import React from "react";
import "./style.css";
import { cn } from "../../utils";

function AnswerSelector2({ id, title, isSelected, onClick }) {
  return (
    <div
      className="selectionContainer2"
      onClick={() => onClick(title)}
    >
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }} className="bg-gradient-to-tr from-zinc-400/20 via-white/90 text-transparent bg-clip-text to-white/10">
        <p className={isSelected ? "choiced2" : "choice2"}><span>{id}</span></p>
        <p className={cn("choiceTitle2" , 'bg-gradient-to-tr from-zinc-400/20 via-white/90 text-transparent bg-clip-text to-white/10')}>{title}</p>
      </div>
      <p className="choiceMarker2">{isSelected && "✓"}</p>
    </div>
  );
}

export default AnswerSelector2;
