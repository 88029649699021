import React from "react";
import "./style.css";
import { cn } from "../../utils";

function HeaderQuestion({ numberQuestion, title, required, description }) {
  return (
    <div className="headerContainer">
      <span
        style={{ marginLeft: "-10px" }}
        className="bg-gradient-to-tr from-zinc-400/20 via-white/90 text-transparent  to-white/10"
      >
        {numberQuestion}
      </span>
      <span style={{ marginLeft: "10px" }}></span>
      <span
        className={cn(
          "font-heading pt-2 pb-2  text-4xl  font-bold   text-gradient translate-y-[10%] text-left bg-gradient-to-tr from-zinc-400/20 via-white/90 text-transparent bg-clip-text to-white/10   md:text-5xl lg:text-5xl [transition:transform_1000ms_cubic-bezier(0.3,1.17,0.55,0.99)0s] [.is-visible_&]:translate-y-0",
          "header",
        )}
      >
        {title}
        {required && (
          <sup
            style={{
              display: "inline-block",
              fontSize: "32px",
            }}
          >
            *
          </sup>
        )}
        <p
          style={{
            display: "block",
            padding: 0,
            margin: 0,
            fontSize: "16px",
            // color: "#000001"
          }}
          className="text-zinc-500/90  font-heading2  "
        >
          {description}
        </p>
      </span>
    </div>
  );
}

export default HeaderQuestion;
