import React, { useContext } from "react";
import "./style.css";
import { HashLink } from "react-router-hash-link";
import { UserContext } from "../../UserContext";
import { Loader2 } from "lucide-react";
function SubmitButton({ title, navigateTo, disabled, onClicked }) {
  const { loading } = useContext(UserContext);
  const { formdata } = useContext(UserContext);

  return (
    <>
      {formdata ? (
        <div className="success">
          <p className="text-white/80 ml-2">Submited. Thank you!</p>

          <div className="bg-gradient-to-tr from-purple-400/10 to-purple-400/20 rounded-3xl border-1 border-white/20 w-[200px] flex justify-center items-center text-muted-foreground">
            <a
              href="https://arezarmada.com"
              className="px-4 py-2"
              target="_blank"
            >
              Go back to home
            </a>
          </div>
        </div>
      ) : (
        <HashLink
          style={{ color: "#0000" }}
          className={`
          } border-white border-1 rounded-2xl text-white px-4 py-3 bg-gradient-to-tr from-purple-400 to-orange-500`}
          smooth
          to={!disabled && ``}
          disabled={disabled || loading}
          onClick={onClicked}
        >
          {/* {loading ? (
            <div className="border-white border-1 rounded-2xl text-white px-4 py-2 bg-gradient-to-tr from-purple-400 to-orange-500">
              ...
            </div>
          ) : ( */}
            <>
              <span className="inline-flex gap-2 text-white">
              
                {title} {loading ? <Loader2 className="animate-spin flex" /> : <></>}
              </span>
              {/* <span>✓</span> */}
            </>
          {/* )} */}
        </HashLink>
      )}
    </>
  );
}

export default SubmitButton;
