import axios from "axios";

const applicationsBackendApi =
  "https://applications-backend-jxmw.onrender.com/client-applications";
export const applicationsBackendHandler = async (
  formData,
  setLoading,
  setFormData,
) => {
  setLoading(true);

  axios
    .post(applicationsBackendApi, formData)
    .then((response) => {
      setLoading(false);
      setFormData(response);
      return response;
    })
    .catch((error) => {
      setLoading(false);
    });
};
