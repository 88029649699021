import React, { useState } from "react";
import HeaderQuestion from "../components/HeaderQuestion";
import SkipButton from "../components/SkipButton";
import SubmitButton from "../components/SubmitButton";
import { googleSheetSubmitHandler } from "../googleService";
import  {Loader2} from 'lucide-react'
import { cn } from "../utils";

function TenthPage({ handleSubmit , formDatas , setFormData }) {
  const [loading , setLoading] = useState(false)
  const [error , setError] = useState(null)
  const pushButton = async () => {
    setLoading(true)
    try {
      
      const res = await  googleSheetSubmitHandler(formDatas , setLoading , setFormData);
    
      if(res){
        setLoading(false)
      }
     

    }catch(er) {
     
      setLoading(false)
      setError('error has occured')
    }


  }

  

  return (
    <div
      id="tenth"
      className={cn('pageContainer' , 'relative w-screen' )}>
      <div>
        <HeaderQuestion
          numbeQuestion="8"
          title="Please confirm you response by clicking the button below."
          required={false}
        />
        {/* <button disabled={loading} onClick={pushButton} className="border-white border-1 rounded-2xl text-white px-4 py-2 bg-gradient-to-tr from-purple-400 to-orange-500">
         {loading ? <span>Confirming...</span> : <span>Confirm</span>}
        </button> */}
        <SubmitButton title="Confirm" isSubmit={true} onClicked={handleSubmit}/>
    
      </div>
      <SkipButton
        nextButton="tenth"
        previousButton="seventh"
        isNextButtonInActive={true}
      />
    
    </div>
  );
}

export default TenthPage;

// const Toaster = () => {
//   return (
//     <div className="absolute top-4 left-[30%] ">
//       <div className="w-[400px] h-[100px] bg-gradient-to-tr from-purple-400/10 via-transparent to-transparent rounded-2xl">
  
//       <p className="flex justify-center items-center text-green-300">
//         You have successfully submited the form
//       </p>

//       </div>

//     </div>
//   )
// }
