import React, { useState, useContext } from "react";
import HeaderQuestion from "../components/HeaderQuestion";
import AnswerSelector from "../components/AnswerSelector";
import SkipButton from "../components/SkipButton";
import Button from "../components/Button";
import { HashLink } from "react-router-hash-link";
import { UserContext } from "../UserContext";

function NinethPage() {
  const [selectedItem, setSelectedItem] = useState(null);
  const { foundUsSource, setFoundUsSource } = useContext(UserContext);

  const handleAnswerSelectorClick = (title, index) => {
    setFoundUsSource(title);
    setSelectedItem(index);
  };

  const handleOKButtonClick = () => {};

  const choices = [
    {
      id: "A",
      title: "Twitter",
    },
    {
      id: "B",
      title: "LinkedIn",
    },
    {
      id: "C",
      title: "Friend/Referals",
    },
    {
      id: "D",
      title: "Blog/NewsLetter",
    },
    {
      id: "E",
      title: "Other",
    },
  ];

  return (
    <div id="seventh" className="pageContainer">
      <div>
        <HeaderQuestion
          numbeQuestion="7"
          title="How’d you find us?"
          required={false}
        />

        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {choices.map((choice, index) => (
            <div style={{ width: "250px" }} key={index}>
              <HashLink
                style={{ color: "white", textDecoration: "none" }}
                smooth
                to={`/#nineth`}
              >
                <AnswerSelector
                  id={choice.id}
                  title={choice.title}
                  isSelected={index === selectedItem} // Compare index with selectedItem
                  onClick={() => handleAnswerSelectorClick(choice.title, index)}
                />
              </HashLink>
            </div>
          ))}
          <Button title="OK" navigateTo="tenth" />
        </div>
      </div>
      {/* this is due some skipped contents out there for some issue which can be filled anytime or changed as we got a time to look at */}
      <SkipButton nextButton="tenth" previousButton="sixth" />
    </div>
  );
}
export default NinethPage;