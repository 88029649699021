import React from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const ErrorCard = ({ sub, title, width }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "5px 5px",
        borderRadius: "8px",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#F7E6E6",
        width: `${width}px`,
        justifyContent: "space-around",
        color: "#AF0404",
      }}
    >
      <FontAwesomeIcon icon={faTriangleExclamation} color={"#AF0404"} />
      <div>
        <span
          style={{ fontWeight: "600", fontFamily: "Outfit", fontSize: "14px" }}
        >
          {sub}
        </span>
        <span style={{ fontFamily: "Outfit", fontSize: "14px" }}>{title}</span>
      </div>
    </div>
  );
};

export default ErrorCard;
