
import React, { useState, useEffect, useContext } from "react";
import AnswerSelector2 from "../components/AnswerSelector2";
import HeaderQuestion from "../components/HeaderQuestion";
import SkipButton from "../components/SkipButton";
import Button from "../components/Button";
import { UserContext } from "../UserContext";

function FouthPage() {
  const [selectedTitles, setSelectedTitles] = useState([]);
  const { requiredSkills, setRequiredSkills } = useContext(UserContext);

  useEffect(() => {
    setRequiredSkills(selectedTitles);
  }, [selectedTitles, setRequiredSkills]);

  const handleAnswerSelectorClick = (title) => {
    if (!selectedTitles.includes(title)) {
      setSelectedTitles([...selectedTitles, title]);
    } else {
      setSelectedTitles(selectedTitles.filter((t) => t !== title));
    }
  };

  const handleOKButtonClick = () => {};

  const choices = [
    {
      id: "A",
      title: "JavaScript",
      isSelected: selectedTitles.includes("JavaScript"),
    },
    {
      id: "B",
      title: "Python",
      isSelected: selectedTitles.includes("Python"),
    },
    {
      id: "C",
      title: "Figma",
      isSelected: selectedTitles.includes("Figma"),
    },
    {
      id: "D",
      title: "React",
      isSelected: selectedTitles.includes("React"),
    },
    {
      id: "E",
      title: "Node.js",
      isSelected: selectedTitles.includes("Node.js"),
    },
    {
      id: "F",
      title: "Redux",
      isSelected: selectedTitles.includes("Redux"),
    },
    {
      id: "G",
      title: "TypeScript",
      isSelected: selectedTitles.includes("TypeScript"),
    },
    {
      id: "H",
      title: "Java",
      isSelected: selectedTitles.includes("Java"),
    },
    {
      id: "I",
      title: "Kotlin",
      isSelected: selectedTitles.includes("Kotlin"),
    },
    {
      id: "J",
      title: "React Native",
      isSelected: selectedTitles.includes("React Native"),
    },
    {
      id: "K",
      title: "Blockchain",
      isSelected: selectedTitles.includes("Blockchain"),
    },
    {
      id: "L",
      title: "AWS",
      isSelected: selectedTitles.includes("AWS"),
    },
    {
      id: "M",
      title: "Not Decided Yet",
      isSelected: selectedTitles.includes("Not Decided Yet"),
    },
  ];

  return (
    <div id="fourth" className="pageContainer">
      <div>
        <HeaderQuestion
          numbeQuestion="4"
          title="What skills should they have?"
          required={false}
          description="If you don't know, that's okay. Just skip."
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 10,
          }}
        >
          {choices.map((choice, index) => (
            <AnswerSelector2
              key={index}
              id={choice.id}
              title={choice.title}
              isSelected={choice.isSelected}
              onClick={() => handleAnswerSelectorClick(choice.title)}
            />
          ))}
        </div>
        <div style={{ marginTop: "10px" }}>
          <Button title="OK" navigateTo="fiveth" />
        </div>
      </div>

      <SkipButton nextButton="fiveth" previousButton="third" />
    </div>
  );
}

export default FouthPage;
